<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <v-checkbox
              v-model="showPopup"
              label="Show popup"
              ref="popupToggle"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <pop-up
      :preserve-elements="elements"
      v-model="showPopup"
    />
  </div>
</template>

<script>
export default {
  components: {
    PopUp: () => import('@/components/smart-popup')
  },
  data () {
    return {
      element: undefined,
      showPopup: false
    }
  },
  async mounted () {
    const element = await this.$waitFor(() => document.querySelector('header .search-text'))
    this.element = { element, isCircle: false }
  },
  computed: {
    elements () {
      return this.element ? [this.element] : []
    }
  }
}

</script>
